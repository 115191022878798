.AUKXIAzs .TAM_zawi {
  margin-bottom: 22px;
}
.__1MJwOe4o:not(:last-child) .TAM_zawi {
  margin-bottom: 22px;
}
.__1MJwOe4o .TAM_zawi {
  display: block;
  width: 100%;
  background-color: #fff;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}
.__1MJwOe4o .t7MFPPdh {
  cursor: pointer;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.oVKx_sJZ {
  margin-bottom: 20px;
  min-height: auto;
}
@media only screen and (max-width: 1224px) {
  .oVKx_sJZ {
    margin-top: 28px;
  }
}
@media only screen and (max-width: 992px) {
  .oVKx_sJZ {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .oVKx_sJZ {
    margin-top: 14px;
    min-height: 100px;
    margin-bottom: 0px;
  }
}
.__8lyN7Yx5 {
  border-radius: 5px;
  -o-object-fit: cover;
     object-fit: cover;
}
.M_qyXg2z {
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  margin-bottom: 6px;
  cursor: pointer;
  color: #343a40;
}
@media only screen and (max-width: 1224px) {
  .M_qyXg2z {
    font-size: 18px;
  }
}
@media only screen and (max-width: 992px) {
  .M_qyXg2z {
    font-size: 18px;
    margin-bottom: 4px;
  }
}
@media only screen and (max-width: 576px) {
  .M_qyXg2z {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 4px;
  }
}
.Mw_MazXN {
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 6px;
  cursor: pointer;
  color: #343a40;
}
@media only screen and (max-width: 1224px) {
  .Mw_MazXN {
    font-size: 14px;
  }
}
@media only screen and (max-width: 992px) {
  .Mw_MazXN {
    font-size: 14px;
    margin-bottom: 4px;
    width: 90%;
  }
}
@media only screen and (max-width: 576px) {
  .Mw_MazXN {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 4px;
  }
}
.a9mSBAS1 {
  font-size: 14px;
  line-height: 24px;
  margin: 0px;
  color: #7b838a;
}
@media only screen and (max-width: 576px) {
  .a9mSBAS1 {
    font-size: 11px;
    line-height: 19.2px;
  }
}
.a9mSBAS1 b {
  color: #00a376;
  font-weight: 600;
}
.a9mSBAS1 a {
  margin-right: 6px;
  font-weight: 600;
  color: #00a376;
}
.Jc5B1RCa {
  font-size: 12px;
  line-height: 24px;
  margin: 0px;
  color: #7b838a;
}
@media only screen and (max-width: 576px) {
  .Jc5B1RCa {
    font-size: 11px;
    line-height: 19.2px;
  }
}
.Jc5B1RCa b {
  color: #00a376;
  font-weight: 600;
}
.Jc5B1RCa p {
  font-weight: 600;
  color: #00a376;
  margin: 0px;
}
.__6bizoCJ2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 21px;
  color: #5b6268;
  text-overflow: ellipsis;
  margin: 8px 0 14px 0;
}
@media only screen and (max-width: 1224px) {
  .__6bizoCJ2 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 992px) {
  .__6bizoCJ2 {
    font-size: 16px;
    margin: 8px 0 16px 0;
  }
}
@media only screen and (max-width: 576px) {
  .__6bizoCJ2 {
    font-size: 12px;
  }
}
.NLeTLy6x {
  margin: 0px;
  min-width: 90%;
}
.oVKx_sJZ a {
  color: #00a376 !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.aqH1llfd .__8BcW_w_b img {
  max-width: 730px;
  max-height: 411px;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aqH1llfd .__8BcW_w_b img {
    max-width: 610px;
    max-height: 342px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aqH1llfd .__8BcW_w_b img {
    max-width: 690px;
    max-height: 387px;
  }
}
@media only screen and (max-width: 767px) {
  .aqH1llfd .__8BcW_w_b img {
    max-width: 100%;
    max-height: 100%;
  }
}
.oVjAnjx_ {
  border-radius: 5px;
}
.__92lkOVZi {
  margin-bottom: 40px;
}
@media only screen and (min-width: 1200px) {
  .__92lkOVZi {
    padding-right: 0px;
  }
}
.dsDg_kDY {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-top: 14px;
  cursor: pointer;
  color: #343a40;
  margin-bottom: 10px !important;
}
@media only screen and (max-width: 1224px) {
  .dsDg_kDY {
    font-size: 24px;
  }
}
@media only screen and (max-width: 992px) {
  .dsDg_kDY {
    font-size: 24px;
  }
}
@media only screen and (max-width: 576px) {
  .dsDg_kDY {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 14px;
  }
}
.rX_lUKH2 {
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
  color: #7b838a;
  margin-bottom: 10px;
}
@media only screen and (max-width: 992px) {
  .rX_lUKH2 {
    line-height: 24px;
  }
}
@media only screen and (max-width: 576px) {
  .rX_lUKH2 {
    font-size: 11px;
    line-height: 19.2px;
  }
}
.rX_lUKH2 b {
  color: #00a376;
  font-weight: 600;
}
.rX_lUKH2 a {
  margin-right: 6px;
  font-weight: 600;
  color: #00a376;
}
.iytg4alp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 16px;
  line-height: 24px;
  color: #5b6268;
}
@media only screen and (max-width: 576px) {
  .iytg4alp {
    font-size: 12px;
    line-height: 16px;
  }
}
.aqH1llfd a {
  color: #00a376 !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

div[class='ant-dropdown-arrow'] {
  right: 2px !important;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.25) !important;
}
.ELJjusfE {
  margin-right: -30px;
  padding: 22px 24px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #5b6268;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px;
}
@media only screen and (max-width: 1200px) {
  .ELJjusfE {
    font-size: 16px;
  }
}
@media only screen and (max-width: 576px) {
  .ELJjusfE {
    font-size: 14px;
  }
}
.ELJjusfE li[class='ant-menu-item ant-menu-item-only-child ant-menu-item-selected'],
.ELJjusfE li[class='ant-menu-item ant-menu-item-only-child ant-menu-item-active ant-menu-item-selected'] {
  background-color: #eeeff0;
  border-radius: 8px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

._70neuF_ {
  margin-bottom: 40px;
}
@media only screen and (max-width: 768px) {
  ._70neuF_ {
    margin-bottom: 32px;
  }
}
@media only screen and (max-width: 480px) {
  ._70neuF_ {
    margin-bottom: 24px;
  }
}
._70neuF_ .PVctWV_B {
  width: 100%;
  display: inline-flex;
  white-space: nowrap;
  overflow-x: auto;
}
._70neuF_ .PVctWV_B::-webkit-scrollbar {
  display: none;
}
._70neuF_ .PVctWV_B .kt7nqF8u h2,
._70neuF_ .PVctWV_B .__8lA9UL2S {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #5b6268;
  margin-bottom: 0;
  padding-bottom: 8px;
}
@media only screen and (max-width: 1200px) {
  ._70neuF_ .PVctWV_B .kt7nqF8u h2,
  ._70neuF_ .PVctWV_B .__8lA9UL2S {
    font-size: 16px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 480px) {
  ._70neuF_ .PVctWV_B .kt7nqF8u h2,
  ._70neuF_ .PVctWV_B .__8lA9UL2S {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 4px;
  }
}
._70neuF_ .PVctWV_B .kt7nqF8u {
  margin-right: 24px;
}
@media only screen and (max-width: 768px) {
  ._70neuF_ .PVctWV_B .kt7nqF8u {
    margin-right: 12px;
  }
}
._70neuF_ .PVctWV_B .kt7nqF8u h2:hover {
  color: #00a376;
}
._70neuF_ .PVctWV_B .kt7nqF8u.__8thJh1TU {
  border-bottom: 2px solid #00a376;
}
._70neuF_ .PVctWV_B .kt7nqF8u.__8thJh1TU h2 {
  color: #00a376;
}
._70neuF_ .PVctWV_B .__8lA9UL2S.__8thJh1TU {
  color: #00a376;
  border-bottom: 2px solid #00a376;
}
._70neuF_ .PVctWV_B .__8lA9UL2S::after {
  margin-left: 10px;
  content: url(/icons/down-arrow.svg);
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.dgTgbvE8 {
  margin-top: 42px;
  padding-bottom: 76px;
}
@media only screen and (max-width: 992px) {
  .dgTgbvE8 {
    margin-top: 41px;
    padding-bottom: 95px;
  }
}
@media only screen and (max-width: 576px) {
  .dgTgbvE8 {
    margin-top: 35px;
    padding-bottom: 44px;
  }
}
@media only screen and (max-width: 480px) {
  .dgTgbvE8 {
    margin-top: 26px;
    padding-bottom: 31px;
  }
}
.dgTgbvE8 .__7nNV4jPS {
  margin-bottom: 12px;
}
@media only screen and (max-width: 768px) {
  .dgTgbvE8 .__7nNV4jPS {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 480px) {
  .dgTgbvE8 .__7nNV4jPS {
    margin-bottom: 4px;
  }
}
.dgTgbvE8 .VfqtgKhZ {
  margin-bottom: 40px;
}
@media only screen and (max-width: 768px) {
  .dgTgbvE8 .VfqtgKhZ {
    margin-bottom: 24px;
  }
}
.dgTgbvE8 .ZKmEzzVt {
  margin-top: 68px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: #00a376;
  text-align: center;
  text-decoration-thickness: 1px;
  cursor: pointer;
}
.dgTgbvE8 .ZKmEzzVt:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
@media only screen and (max-width: 576px) {
  .dgTgbvE8 .ZKmEzzVt {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 20px;
  }
}
.dgTgbvE8 .ZKmEzzVt img {
  margin-left: 5px !important;
  margin-bottom: 9px !important;
}
.dgTgbvE8 .yhhRWWne {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 20px 0;
  cursor: pointer;
}
.dgTgbvE8 .yhhRWWne p {
  margin-bottom: 0;
  margin-right: 12px;
  font-weight: 600;
  color: #00a376;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.uMN4amBx .x_6tdNOs {
  width: 100%;
  margin-top: 20px;
}
@media only screen and (max-width: 576px) {
  .uMN4amBx .x_6tdNOs {
    margin-top: 24px;
  }
}
.uMN4amBx .x_6tdNOs .RfTnGfev {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 24px;
}
@media only screen and (max-width: 576px) {
  .uMN4amBx .x_6tdNOs .RfTnGfev {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 16px;
  }
}
.uMN4amBx .x_6tdNOs .zO3TWPP_ {
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 16px;
  margin-bottom: 0;
}
@media only screen and (max-width: 768px) {
  .uMN4amBx .x_6tdNOs .zO3TWPP_ {
    font-size: 12px;
    margin-top: 14px;
  }
}
@media only screen and (max-width: 576px) {
  .uMN4amBx .x_6tdNOs .zO3TWPP_ {
    font-size: 11px;
    line-height: 14px;
    margin-top: 6px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.P1fVCGSv {
  position: relative;
  padding-top: 80px;
  padding-bottom: 70px;
}
@media only screen and (max-width: 768px) {
  .P1fVCGSv {
    padding-top: 38px;
    padding-bottom: 34px;
  }
}
@media only screen and (max-width: 576px) {
  .P1fVCGSv {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.P1fVCGSv .E79t0TA3,
.P1fVCGSv .gMxsIwf_ {
  position: absolute;
  top: 0;
}
.P1fVCGSv .E79t0TA3 {
  left: 0;
  margin-top: 21px;
}
.P1fVCGSv .gMxsIwf_ {
  right: 0;
  margin-top: 66px;
}
.P1fVCGSv .M5pWD_VT:first-child {
  padding-left: 0 !important;
}
.P1fVCGSv .M5pWD_VT:last-child {
  padding-right: 0 !important;
}
.P1fVCGSv .M5pWD_VT .IkfE06Fv {
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 16px;
  margin-bottom: 0;
}
@media only screen and (max-width: 576px) {
  .P1fVCGSv .M5pWD_VT .IkfE06Fv {
    font-size: 12px;
    margin-top: 4px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.IdKOKs0C {
  background-color: #fff;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

